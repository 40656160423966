export const RouterName = {
  auth: {
    login: "/auth/login",
    signup: "",
    forgotPassword: "/auth/forgot-password",
    resetPassword: "/auth/reset-password/:token",
    maintenance: "/auth/maintenance",
  },

  home: {
    home: "/dashboards",
  },
  stores: {
    stores: "/stores",
  },
  admins: "/admins",
  privileges: "/privileges",
  branche: "/branch/:id",
  client: "/client",
  course: "/course/:id",
  request: "/request/:id",
  requestBr: "/requestBr/:id",
  allrequest: "/all",
  branchstuff: "/stuff/:id",
  dashboard: "/dashboard",
};
