import React, { lazy } from "react";
import { Navigate } from "react-router-dom";
import Loadable from "../layouts/full/shared/loadable/Loadable";
import { RouterName } from "./RouterName";
import { dataLocalStorage, getLocalStorage } from "src/helper/publicFunction";

/* ***Layouts**** */
const FullLayout = Loadable(lazy(() => import("../layouts/full/FullLayout")));
const BlankLayout = Loadable(
  lazy(() => import("../layouts/blank/BlankLayout"))
);

/* ****Pages***** */
const Stores = Loadable(lazy(() => import("src/pages/store/view")));
const Admins = Loadable(lazy(() => import("src/pages/admins/admins/view")));
const Privileges = Loadable(
  lazy(() => import("src/pages/admins/privileges/view"))
);
const Branche = Loadable(lazy(() => import("src/pages/store_branches/view")));
const Client = Loadable(lazy(() => import("src/pages/client/view")));
const Course = Loadable(lazy(() => import("src/pages/clientCours/view")));
const Request = Loadable(lazy(() => import("src/pages/clientRequest/view")));
const RequestBr = Loadable(lazy(() => import("src/pages/BranchRequest/view")));
const AllRequest = Loadable(lazy(() => import("src/pages/Requests/view")));
const BranchStuff = Loadable(lazy(() => import("src/pages/stuff/view")));
const Dashboard = Loadable(lazy(() => import("src/pages/dashboard/view")));
/* ****Apps***** */
const Chats = Loadable(lazy(() => import("../views/apps/chat/Chat")));
const Notes = Loadable(lazy(() => import("../views/apps/notes/Notes")));
const Calendar = Loadable(
  lazy(() => import("../views/apps/calendar/BigCalendar"))
);
const Email = Loadable(lazy(() => import("../views/apps/email/Email")));
const Blog = Loadable(lazy(() => import("../views/apps/blog/Blog")));
const BlogDetail = Loadable(lazy(() => import("../views/apps/blog/BlogPost")));
const Tickets = Loadable(lazy(() => import("../views/apps/tickets/Tickets")));
const Contacts = Loadable(
  lazy(() => import("../views/apps/contacts/Contacts"))
);
const Ecommerce = Loadable(
  lazy(() => import("../views/apps/eCommerce/Ecommerce"))
);
const EcommerceDetail = Loadable(
  lazy(() => import("../views/apps/eCommerce/EcommerceDetail"))
);
const EcomProductList = Loadable(
  lazy(() => import("../views/apps/eCommerce/EcomProductList"))
);
const EcomProductCheckout = Loadable(
  lazy(() => import("../views/apps/eCommerce/EcommerceCheckout"))
);
const UserProfile = Loadable(
  lazy(() => import("../views/apps/user-profile/UserProfile"))
);
const Followers = Loadable(
  lazy(() => import("../views/apps/user-profile/Followers"))
);
const Friends = Loadable(
  lazy(() => import("../views/apps/user-profile/Friends"))
);
// const Gallery = Loadable(
//   lazy(() => import("../views/apps/user-profile/Gallery"))
// );

// Pages
const RollbaseCASL = Loadable(
  lazy(() => import("../views/pages/rollbaseCASL/RollbaseCASL"))
);
const Treeview = Loadable(
  lazy(() => import("../views/pages/treeview/Treeview"))
);
const Pricing = Loadable(lazy(() => import("../views/pages/pricing/Pricing")));
const AccountSetting = Loadable(
  lazy(() => import("../views/pages/account-setting/AccountSetting"))
);
const Faq = Loadable(lazy(() => import("../views/pages/faq/Faq")));

// widget
const WidgetCards = Loadable(
  lazy(() => import("../views/widgets/cards/WidgetCards"))
);
const WidgetBanners = Loadable(
  lazy(() => import("../views/widgets/banners/WidgetBanners"))
);
const WidgetCharts = Loadable(
  lazy(() => import("../views/widgets/charts/WidgetCharts"))
);

// form elements
const MuiAutoComplete = Loadable(
  lazy(() => import("../views/forms/form-elements/MuiAutoComplete"))
);
const MuiButton = Loadable(
  lazy(() => import("../views/forms/form-elements/MuiButton"))
);
const MuiCheckbox = Loadable(
  lazy(() => import("../views/forms/form-elements/MuiCheckbox"))
);
const MuiRadio = Loadable(
  lazy(() => import("../views/forms/form-elements/MuiRadio"))
);
const MuiSlider = Loadable(
  lazy(() => import("../views/forms/form-elements/MuiSlider"))
);
const MuiDateTime = Loadable(
  lazy(() => import("../views/forms/form-elements/MuiDateTime"))
);
const MuiSwitch = Loadable(
  lazy(() => import("../views/forms/form-elements/MuiSwitch"))
);

// form layout
const FormLayouts = Loadable(lazy(() => import("../views/forms/FormLayouts")));
const FormCustom = Loadable(lazy(() => import("../views/forms/FormCustom")));
const FormWizard = Loadable(lazy(() => import("../views/forms/FormWizard")));
const FormValidation = Loadable(
  lazy(() => import("../views/forms/FormValidation"))
);
const QuillEditor = Loadable(
  lazy(() => import("../views/forms/quill-editor/QuillEditor"))
);
const FormHorizontal = Loadable(
  lazy(() => import("../views/forms/FormHorizontal"))
);
const FormVertical = Loadable(
  lazy(() => import("../views/forms/FormVertical"))
);

// tables
const BasicTable = Loadable(lazy(() => import("../views/tables/BasicTable")));
const CollapsibleTable = Loadable(
  lazy(() => import("../views/tables/CollapsibleTable"))
);
const EnhancedTable = Loadable(
  lazy(() => import("../views/tables/EnhancedTable"))
);
const FixedHeaderTable = Loadable(
  lazy(() => import("../views/tables/FixedHeaderTable"))
);
const PaginationTable = Loadable(
  lazy(() => import("../views/tables/PaginationTable"))
);
const SearchTable = Loadable(lazy(() => import("../views/tables/SearchTable")));

// chart
const LineChart = Loadable(lazy(() => import("../views/charts/LineChart")));
const GredientChart = Loadable(
  lazy(() => import("../views/charts/GredientChart"))
);
const DoughnutChart = Loadable(
  lazy(() => import("../views/charts/DoughnutChart"))
);
const AreaChart = Loadable(lazy(() => import("../views/charts/AreaChart")));
const ColumnChart = Loadable(lazy(() => import("../views/charts/ColumnChart")));
const CandlestickChart = Loadable(
  lazy(() => import("../views/charts/CandlestickChart"))
);
const RadialbarChart = Loadable(
  lazy(() => import("../views/charts/RadialbarChart"))
);

// ui
const MuiAlert = Loadable(
  lazy(() => import("../views/ui-components/MuiAlert"))
);
const MuiAccordion = Loadable(
  lazy(() => import("../views/ui-components/MuiAccordion"))
);
const MuiAvatar = Loadable(
  lazy(() => import("../views/ui-components/MuiAvatar"))
);
const MuiChip = Loadable(lazy(() => import("../views/ui-components/MuiChip")));
const MuiDialog = Loadable(
  lazy(() => import("../views/ui-components/MuiDialog"))
);
const MuiList = Loadable(lazy(() => import("../views/ui-components/MuiList")));
const MuiPopover = Loadable(
  lazy(() => import("../views/ui-components/MuiPopover"))
);
const MuiRating = Loadable(
  lazy(() => import("../views/ui-components/MuiRating"))
);
const MuiTabs = Loadable(lazy(() => import("../views/ui-components/MuiTabs")));
const MuiTooltip = Loadable(
  lazy(() => import("../views/ui-components/MuiTooltip"))
);
const MuiTransferList = Loadable(
  lazy(() => import("../views/ui-components/MuiTransferList"))
);
const MuiTypography = Loadable(
  lazy(() => import("../views/ui-components/MuiTypography"))
);

// authentication
const Login = Loadable(
  lazy(() => import("../views/authentication/auth/Login"))
);

const ForgotPassword = Loadable(
  lazy(() => import("../views/authentication/auth/ForgotPassword"))
);
const ResetPassword = Loadable(
  lazy(() => import("src/views/authentication/auth/ResetPassword"))
);

const Error = Loadable(lazy(() => import("../views/authentication/Error")));
const Maintenance = Loadable(
  lazy(() => import("../views/authentication/Maintenance"))
);
let data = getLocalStorage(dataLocalStorage.privileges);
const Router = [
  {
    path: "/",
    element: <FullLayout />,
    children: [
      {
        path: "/",
        exact: true,
        element: (
          <Navigate
            to={
              data["10"]?.access_read === 1 ?
                RouterName.dashboard :
                data["7"]?.access_read === 1
                  ? RouterName.stores.stores
                  : data["1"]?.access_read === 1
                    ? RouterName.admins
                    : data["4"]?.access_read === 1
                      ? RouterName.client
                      : data["5"]?.access_read === 1
                        ? RouterName.request
                        : null
            }
          />
        ),
      },

      data["7"]?.access_read === 1 && {
        path: RouterName.stores.stores,
        exact: true,
        element: <Stores />,
      },
      data["1"]?.access_read === 1 && {
        path: RouterName.admins,
        exact: true,
        element: <Admins />,
      },
      data["1"]?.access_read === 1 && {
        path: RouterName.privileges,
        exact: true,
        element: <Privileges />,
      },
      data["3"]?.access_read === 1 && {
        path: RouterName.branche,
        exact: true,
        element: <Branche />,
      },

      data["4"]?.access_read === 1 && {
        path: RouterName.client,
        exact: true,
        element: <Client />,
      },
      data["6"]?.access_read === 1 && {
        path: RouterName.course,
        exact: true,
        element: <Course />,
      },
      data["5"]?.access_read === 1 && {
        path: RouterName.request,
        exact: true,
        element: <Request />,
      },
      {
        path: RouterName.requestBr,
        exact: true,
        element: <RequestBr />,
      },
      {
        path: RouterName.allrequest,
        exact: true,
        element: <AllRequest />,
      },
      data["9"]?.access_read === 1 && {
        path: RouterName.branchstuff,
        exact: true,
        element: <BranchStuff />,
      },
      data["10"]?.access_read === 1 && {
        path: RouterName.dashboard,
        exact: true,
        element: <Dashboard />,
      },
      // -----------------------------------------------------------------------------
    ],
  },
  {
    path: "/",
    element: <BlankLayout />,
    children: [
      { path: "/auth/404", element: <Error /> },
      { path: RouterName.auth.login, element: <Login /> },
      { path: RouterName.auth.forgotPassword, element: <ForgotPassword /> },
      { path: RouterName.auth.resetPassword, element: <ResetPassword /> },
      { path: RouterName.auth.maintenance, element: <Maintenance /> },
      { path: "*", element: <Navigate to="/auth/404" /> },
    ],
  },
];

export default Router;
